import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import asyncComponent from './containers/AsyncComponent';

const components: NodeListOf<Element> = document.querySelectorAll(
    '[data-component]'
);

components.forEach((el: Element) => {
    if (el instanceof HTMLElement) {
        const componentName = el.dataset.component;
        const componentProps = el.dataset.props;

        el.removeAttribute('data-component');
        el.removeAttribute('data-props');

        const AsyncComponent = asyncComponent(() => {
            return import(`./components/async/${componentName}`);
        });

        ReactDOM.render(
            <React.StrictMode>
                <Suspense fallback="Loading...">
                    {componentProps ? (
                        <AsyncComponent {...JSON.parse(componentProps)} />
                    ) : (
                        <AsyncComponent />
                    )}
                </Suspense>
            </React.StrictMode>,
            el
        );
    }
});
